import { studioPublicApiAxiosV1, studioPublicAxiosV1 } from 'lib/axios';

import type { EventCommentPageProps } from 'types/comment';
import type {
  EventImageGalleryPageProps,
  EventResultPagesType,
  LeaderboardDetailData,
  LeaderboardRecord,
  LeaderboardTeamData,
} from 'types/event';

/**
 * @description
 * - 이 함수는 오직 서버사이드에서만 사용되며 공개적인 이벤트만 반환합니다.
 */
export const getPublicLeaderboardDetail = async (leaderboardId: number) =>
  await studioPublicAxiosV1<LeaderboardDetailData>({
    method: 'GET',
    url: `/leaderboard/${leaderboardId}`,
  });

export const getLeaderboardSchedule = async (
  leaderboardId: number,
): Promise<LeaderboardRecord[]> =>
  await studioPublicAxiosV1({
    method: 'GET',
    url: `/leaderboard/${leaderboardId}/record`,
  });

export const getLeaderboardTeamList = async (
  leaderboardId: number,
): Promise<LeaderboardTeamData[]> =>
  await studioPublicAxiosV1({
    method: 'GET',
    url: `/leaderboard/${leaderboardId}/team`,
  });

export const getEventImages = async (
  leaderboardId: number,
  limit: number,
  offset: number,
): Promise<EventImageGalleryPageProps> =>
  await studioPublicAxiosV1({
    method: 'GET',
    url: `/leaderboard/${leaderboardId}/images?limit=${limit}&offset=${offset}`,
  });

export const getEventList = (queries: string): Promise<EventResultPagesType> =>
  studioPublicApiAxiosV1({
    method: 'GET',
    url: `/events${queries}&useOrderByAdminPick=true`,
  });

export const getTeamSearchResult = ({
  q,
  joinUser,
  limit,
  offset,
}: {
  q: string;
  joinUser: boolean;
  limit: number;
  offset: number;
}) =>
  studioPublicApiAxiosV1({
    method: 'GET',
    url: `/teams?q=${q}&joinUser=${joinUser}&limit=${limit}&offset=${offset}`,
  });

export const getOpenTalkList = async (
  eventId: number,
  limit: number,
  offset: number,
): Promise<EventCommentPageProps> =>
  studioPublicApiAxiosV1({
    method: 'GET',
    url: `/events/${eventId}/comments?limit=${limit}&offset=${offset}&joinUser=true&joinUserChannel=true&orderByCreatedAt=DESC`,
  });

export const increaseEventViewCount = async (leaderboardId: number) =>
  await studioPublicAxiosV1({
    method: 'PATCH',
    url: `/leaderboard/views/${leaderboardId}`,
  });
