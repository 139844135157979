import { useEffect, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';

import type { ButtonProps } from '@mui/material';
import { Skeleton } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import useAuthApi from 'api/hooks/useAuthApi';

import CommonDialog from 'components/dialogs/CommonDialog';

import useActiveAuth from 'hooks/useActiveAuth';
import useQueryEvents from 'hooks/useQueryEvents';
import useTanstackQuery from 'hooks/useTanstackQuery';
import useToggle from 'hooks/useToggle';

import CustomSetButton from './CustomSetButton';

interface FollowButtonProps extends Omit<ButtonProps, 'color'> {
  channelOwnerUid: string;
  isMyChannel: boolean;
}

const FollowButton = ({
  channelOwnerUid,
  isMyChannel,
  sx,
  ...rest
}: FollowButtonProps) => {
  const { isProfileLoadProcessing, isAuthenticatedUser, isAnonymousUser } =
    useActiveAuth();
  const { followUser, getIsUserFollowing } = useAuthApi();
  const { t } = useTranslation('scoreboard');
  const { invalidateQueries } = useTanstackQuery();
  const {
    isOpen: isUnfollowDialogOpen,
    open: openUnfollowDialog,
    close: closeUnfollowDialog,
  } = useToggle();

  const [isFollow, setIsFollow] = useState<boolean>(false);
  const [isFollowLoading, setIsFollowLoading] = useState<boolean>(false);

  const { data: isInitFollow, isLoading } = useQuery({
    queryKey: ['follow', channelOwnerUid],
    queryFn: async () => await getIsUserFollowing(channelOwnerUid),
    enabled: isAuthenticatedUser,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });
  useQueryEvents(
    { data: isInitFollow },
    {
      onSuccess: (data) => setIsFollow(data),
    },
  );

  const changeFollowing = async () => {
    setIsFollowLoading(true);
    setIsFollow((prev) => !prev);
    await followUser(channelOwnerUid);
    invalidateQueries(['follow']);
    setIsFollowLoading(false);
  };

  const handleFollowBtnClick = async () => {
    if (isAnonymousUser) {
      enqueueSnackbar(t('errorMessage.loginRequire'), {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      return;
    }

    try {
      if (isFollow) openUnfollowDialog();
      else await changeFollowing();
    } catch {
      enqueueSnackbar(t('errorMessage.followError'), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (isInitFollow !== undefined) {
      setIsFollow(isInitFollow);
    }
  }, [isInitFollow]);

  return isMyChannel ? null : (
    <>
      {isFollow !== null ? (
        <CustomSetButton
          className="gtm-channel-follow-btn"
          title={isFollow ? t('following') : t('follow')}
          isLoading={isLoading || isFollowLoading || isProfileLoadProcessing}
          disabled={isLoading || isFollowLoading}
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            await handleFollowBtnClick();
          }}
          sx={{
            ...sx,
          }}
          variant={isFollow ? 'outlinedAssistive' : 'solid'}
          {...rest}
        />
      ) : (
        <Skeleton
          variant="rounded"
          sx={{ width: '90px', height: '40px', ...sx }}
        />
      )}

      {isUnfollowDialogOpen ? (
        <CommonDialog.Delete
          open={isUnfollowDialogOpen}
          onClose={closeUnfollowDialog}
          disableDelete={isFollowLoading}
          title={t('unfollowTitle')}
          description={t('unfollowMessage')}
          deleteText={t('unfollow')}
          onDelete={() => changeFollowing()}
        />
      ) : null}
    </>
  );
};

export default FollowButton;
