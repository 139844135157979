import type { ButtonProps } from '@mui/material';
import { Button, CircularProgress } from '@mui/material';

const CustomSetButton = ({
  title,
  disabled,
  isLoading,
  sx,
  ...buttonProps
}: { title: string; isLoading?: boolean } & ButtonProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      disabled={isLoading || disabled}
      sx={{
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        ...sx,
      }}
      {...buttonProps}
    >
      {isLoading ? <CircularProgress size={24} /> : title}
    </Button>
  );
};

export default CustomSetButton;
